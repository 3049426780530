import {Responsibles} from '@src/components/editableFields/Responsibles';
import {IEditableTaskAction, IEditableTaskState} from '@src/core/hooks/states/useEditableTaskState';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IRuleParams} from '@tehzor/tools/utils/responsibilityRules/IRuleParams';
import {Dispatch, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {isEqual} from 'lodash';

interface ITaskResponsiblesProps {
	objectId: string;
	stage?: ObjectStageIds;
	scope?: string;
	editingState: IEditableTaskState;
	editingDispatch: Dispatch<IEditableTaskAction>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	exception?: boolean;
}

export const EditableTaskResponsibles = ({
	objectId,
	stage,
	scope,
	editingState,
	editingDispatch,
	required,
	disabled,
	hasError,
	exception
}: ITaskResponsiblesProps) => {
	const {t} = useTranslation();

	const {planId, respUsers, activeGroup, initialRuleParams} = editingState;
	const ruleParams: IRuleParams = useMemo(() => ({planId}), [planId]);

	const handleChange = useCallback(
		(users: string[], group?: string) => {
			editingDispatch({type: 'update', field: 'respUsers', value: users});
			if (required) {
				editingDispatch({type: 'update-error', field: 'respUsers'});
			}

			if (!group) {
				return;
			}

			editingDispatch({type: 'update', field: 'activeGroup', value: group});
			if (required) {
				editingDispatch({type: 'update-error', field: 'activeGroup'});
			}
		},
		[required]
	);

	const handleUpdateException = useCallback(
		(value: boolean) => {
			if (exception) {
				return;
			}

			editingDispatch({type: 'update-exception', field: 'respUsers', value});
		},
		[exception]
	);

	const checkDoSuggestions = useCallback(() => {
		if (isEqual(initialRuleParams, ruleParams)) {
			return false;
		}
		editingDispatch({type: 'update', field: 'initialRuleParams', value: undefined});
		return true;
	}, [initialRuleParams, ruleParams]);

	return (
		<Responsibles
			objectId={objectId}
			stage={stage}
			scope={scope}
			workingGroupType={WorkingGroupTypeId.PERFORMERS}
			respUsers={respUsers}
			activeGroup={activeGroup}
			ruleParams={ruleParams}
			label={t('editingTaskDialog.taskResponsibles.label')}
			errorLabel={t('editingTaskDialog.taskResponsibles.errorLabel')}
			required={required}
			disabled={disabled}
			hasError={hasError}
			onResponsiblesChange={handleChange}
			onUpdateException={handleUpdateException}
			checkDoSuggestions={checkDoSuggestions}
		/>
	);
};
